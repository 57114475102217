import { DefaultTheme } from 'styled-components/macro';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  primary: {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#ffd241',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
  },
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    350: '#bbbbbb',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
};

const lightTheme: DefaultTheme = {
  ...colors,
  // Defaults
  ease: {
    standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  },

  // Semantics
  bgColor: colors.primary[300],
  sheetColor: colors.gray[100],
  textColor: colors.gray[800],
  textFaded: colors.gray[500],
  hoverBg: colors.gray[200],
  dividerColor: colors.gray[350],

  sheetShadow: '2px 4px 8px rgba(23, 23, 23, 0.08), 2px 4px 12px rgba(23, 23, 23, 0.12)',
};

export default lightTheme;
