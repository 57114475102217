import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

import type { Note } from '../lib/config';

const hoverNoteAtom = atom<Note | null>({
  key: 'hoverNote',
  default: null,
  effects_UNSTABLE: localStorageEffect('hoverNote'),
});
export default hoverNoteAtom;
