import styled from 'styled-components/macro';

import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Divider from '../../components/Divider';
import Row from '../../components/Row';

const TBD = styled.h3`
  text-align: center;
`;

export default function TempoSheet() {
  return (
    <Sheet>
      <Row align='flex-end' padding='32px 24px 0'>
        <SheetHeading>tempo</SheetHeading>
      </Row>
      <Divider width='calc(100% - 48px)' />
      <TBD>TBD</TBD>
    </Sheet>
  );
}
