import { DefaultTheme } from 'styled-components/macro';
import lightTheme, { colors } from './lightTheme';

const darkTheme: DefaultTheme = {
  // Duplicate defaults from light
  ...lightTheme,

  // Override light semantics
  bgColor: colors.gray[800],
  sheetColor: colors.gray[900],
  textColor: colors.gray[50],
  textFaded: colors.gray[400],
  hoverBg: colors.gray[800],
  dividerColor: colors.gray[700],

  sheetShadow: '2px 4px 12px rgba(23, 23, 23, 0.5), 2px 4px 8px rgba(23, 23, 23, 0.35)',
};

export default darkTheme;
