import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import WebMidi from 'webmidi';
import type { InputEventNoteon, InputEventNoteoff, InputEventControlchange } from 'webmidi';
import keyModeAtom from '../../state/keyModeAtom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import keyRootAtom from '../../state/keyRootAtom';
import { ALL_NOTE_NAMES, NATURAL_NOTE_NAMES, PADDED_ACCIDENTAL_NOTE_NAMES } from '../../lib/config';
import type { Note, KeyMode } from '../../lib/config';
import { useRef } from 'react';
import hoverNoteAtom from '../../state/hoverNoteAtom';
import activeMidiNotesAtom, { activeMidiNoteSelector } from '../../state/activeNotesAtom';
import NoteWithSymbols from '../../components/NoteWithSymbol';

// Note label styles
const Wrapper = styled.div`
  padding: 0 48px 24px;
  @media (max-width: 420px) {
    padding: 0 24px 24px;
  }
`;
const AccidentalLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const NoteLabel = styled.button`
  position: relative;
  display: block;
  appearance: none;
  border: none;
  outline: none;

  user-select: none;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;

  white-space: nowrap;
  letter-spacing: -1.75px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};

  opacity: 0.75;
  transition: opacity 200ms ${({ theme }) => theme.ease.standard},
    transform 200ms ${({ theme }) => theme.ease.standard};

  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.hoverBg};
  }
  &.active:not(.disabled) {
    opacity: 1;
    color: ${({ theme }) => theme.gray[800]};
    background-color: ${({ theme }) => theme.primary[300]};
  }

  &.disabled {
    opacity: 0.33;
  }

  &.placeholder {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    width: 12px;
    height: 12px;
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

// Keyboard styles
const KeyboardWrapper = styled.div`
  flex-shrink: 0;

  position: relative;
  display: flex;
  gap: 2px;
  align-items: stretch;
  height: 128px;

  /* For keyboard animations */
  perspective: 256px;
  perspective-origin: top center;
`;
const NaturalNote = styled.button`
  display: block;
  appearance: none;
  border: none;
  outline: none;

  flex: 1;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray[100]};
  border: 2px solid ${({ theme }) => theme.gray[350]};
  transition: opacity 350ms ${({ theme }) => theme.ease.standard},
    background-color 150ms ${({ theme }) => theme.ease.standard},
    transform 200ms ${({ theme }) => theme.ease.standard};
  transform-origin: top center;

  &:hover:not(:disabled),
  &.hover:not(:disabled) {
    background-color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.gray[400]};
    box-shadow: ${({ theme }) => theme.sheetShadow};
    transform: scale(1.01, 1.02) rotateX(1deg);
  }
  &.active:not(:disabled) {
    background-color: ${({ theme }) => theme.primary[300]};
    border: 2px solid ${({ theme }) => theme.primary[400]};
    box-shadow: ${({ theme }) => theme.sheetShadow};
    transform: scale(1.01, 1.02) rotateX(-1deg);
  }
  :disabled {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.gray[350]};
    border: 2px solid ${({ theme }) => theme.gray[500]};
  }
`;
const AccidentalNotesWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: -2px;
  left: 0;
  width: 100%;
  height: 67%;
  pointer-events: none;
`;
const AccidentalNote = styled.button`
  display: block;
  appearance: none;
  border: none;
  outline: none;

  width: ${100 / 7 / 1.75}%;
  &:first-child,
  &:last-child {
    width: ${100 / 7 / 2 / 2}%;
  }

  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.sheetColor};
  background-color: ${({ theme }) => theme.gray[800]};

  transition: opacity 350ms ${({ theme }) => theme.ease.standard},
    background-color 150ms ${({ theme }) => theme.ease.standard},
    transform 200ms ${({ theme }) => theme.ease.standard};
  transform-origin: top center;

  &:hover:not(:disabled),
  &.hover:not(:disabled) {
    background-color: ${({ theme }) => theme.black};
    box-shadow: ${({ theme }) => theme.sheetShadow};
    transform: scale(1.015, 1.03) rotateX(1.5deg);
  }
  &.active:not(:disabled) {
    background-color: ${({ theme }) => theme.primary[300]};
    border: 2px solid ${({ theme }) => theme.primary[400]};
    box-shadow: ${({ theme }) => theme.sheetShadow};
    transform: scale(1.015, 1.03) rotateX(-1.5deg);
  }
  :disabled {
    opacity: 0.25;
  }

  pointer-events: auto;
  &.placeholder {
    opacity: 0;
    pointer-events: none;
  }
`;

// Component
export default function Keyboard() {
  const [keyRoot, setKeyRoot] = useRecoilState(keyRootAtom);
  const [keyMode, setKeyMode] = useRecoilState(keyModeAtom);
  const [hoverNote, setHoverNote] = useRecoilState(hoverNoteAtom);

  // Set/get active midi note(s)
  const setActiveMidiNotes = useSetRecoilState(activeMidiNotesAtom);
  const activeMidiNote = useRecoilValue(activeMidiNoteSelector);

  // useEffect(() => {
  //   console.log('activeMidiNote', activeMidiNote);
  // }, [activeMidiNote]);

  // Active notes management helpers
  const isSustainDown = useRef(false);
  // const [isSustainDown, setIsSustainDown] = useState(false);
  const appendNote = useCallback(
    (midiNote: number) => setActiveMidiNotes(prev => [...prev, midiNote]),
    [setActiveMidiNotes]
  );
  const removeNote = useCallback(
    (midiNote: number) =>
      // Don't remove notes when sustain is down
      !isSustainDown.current &&
      setActiveMidiNotes(prev => {
        const newActiveNotes = [...prev];
        const idx = newActiveNotes.findIndex(match => match === midiNote);
        if (idx > -1) {
          newActiveNotes.splice(idx, 1);
          return newActiveNotes;
        }
        return prev;
      }),
    [setActiveMidiNotes]
  );

  // Midi Note handler
  useEffect(() => {
    function handleNote(input: InputEventNoteon | InputEventNoteoff) {
      const { type, note } = input;
      const { number } = note;
      // Always play in the 4th octave
      if (type === 'noteon') appendNote(number);
      if (type === 'noteoff') removeNote(number);
    }

    function handleSustain(input: InputEventControlchange) {
      const { name } = input.controller;
      if (name !== 'holdpedal') return;
      isSustainDown.current = input.value === 127;
      // Clear all notes when sustain comes up
      if (input.value === 0) setActiveMidiNotes([]);
    }

    // Input listener helper funcs
    function addInputListeners() {
      for (const input of WebMidi.inputs) {
        input.addListener('noteon', 'all', handleNote);
        input.addListener('noteoff', 'all', handleNote);
        input.addListener('controlchange', 'all', handleSustain);
      }
    }
    function clearInputListeners() {
      for (const input of WebMidi.inputs) input.removeListener();
      setActiveMidiNotes([]); // And reset all active notes just to be safe
      isSustainDown.current = false; // And clear sustain state
    }
    function refreshInputListeners() {
      console.log('Device list changed—refreshing input listeners.');
      clearInputListeners();
      addInputListeners();
    }

    // Initialization
    async function initializeWebMidi() {
      try {
        await WebMidi.enable();
        // Add slight delay to make sure MIDI is fully enabled
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log('WebMidi enabled!');

        // Add initial input listeners
        addInputListeners();

        // Refresh input listeners anytime midi device become availalbe/unavailable
        WebMidi.addListener('connected', refreshInputListeners);
        WebMidi.addListener('disconnected', refreshInputListeners);
      } catch (err) {
        console.error('WebMidi could not be enabled.', err);
      }
    }
    initializeWebMidi();

    // On unmount, cleanup listeners ( for all inputs and device connect/disconnect )
    return () => {
      console.log('Clearning up MIDI listeners');
      WebMidi.removeListener();
      clearInputListeners();
    };
  }, [appendNote, removeNote, setActiveMidiNotes]);

  // Track mouse click status
  const [isMouseDown, setIsMouseDown] = useState(false);
  const setMouseDown = useCallback(() => setIsMouseDown(true), []);
  const setMouseUp = useCallback(() => setIsMouseDown(false), []);
  useEffect(() => {
    window.addEventListener('mousedown', setMouseDown, true);
    window.addEventListener('mouseup', setMouseUp, true);
    return () => {
      window.removeEventListener('mousedown', setMouseDown, true);
      window.removeEventListener('mouseup', setMouseUp, true);
    };
  }, [setMouseDown, setMouseUp]);

  // Property spreads
  // NoteLabel click/unclick handlers
  const noteLabelSpread = useCallback(
    ({ disabled, note }: { disabled: boolean; note: Note }) => {
      let className = keyMode !== 'chromatic' && keyRoot === note ? 'active ' : '';
      if (disabled) className += 'disabled';
      return {
        className,
        onClick: () => {
          let newMode: KeyMode | null = null;
          setKeyRoot(prev => {
            if (prev === note && keyMode !== 'chromatic') {
              newMode = 'chromatic';
              return prev;
            } else {
              if (keyMode === 'chromatic') newMode = 'major';
              return note;
            }
          });
          if (newMode) setKeyMode(newMode);
        },
      };
    },
    [keyMode, keyRoot, setKeyMode, setKeyRoot]
  );
  // Note click/unclick handler
  const noteSpread = useCallback(
    (note: Note) => {
      const midiIdx = ALL_NOTE_NAMES({ keyRoot, keyMode }).findIndex(([, match]) => match === note);
      if (midiIdx > -1) {
        let className = activeMidiNote !== null && activeMidiNote % 12 === midiIdx ? 'active ' : '';
        if (hoverNote === note) className += ' hover';
        // Always play in the 4th octave
        const midiNote = midiIdx + 4 * 12;
        return {
          className,
          onMouseDown: () => appendNote(midiNote),
          onMouseUp: () => removeNote(midiNote),
          onMouseEnter: () => {
            setHoverNote(note);
            isMouseDown && appendNote(midiNote);
          },
          onMouseLeave: () => {
            setHoverNote(prev => (prev === note ? null : prev));
            isMouseDown && removeNote(midiNote);
          },
        };
      }
    },
    [activeMidiNote, appendNote, hoverNote, isMouseDown, keyMode, keyRoot, removeNote, setHoverNote]
  );

  return (
    <Wrapper>
      {/* Accidental label buttons */}
      <AccidentalLabelWrapper>
        {PADDED_ACCIDENTAL_NOTE_NAMES({ keyRoot, keyMode }).map(([disabled, note, flatName], idx) =>
          note === null ? (
            <NoteLabel key={idx} className='placeholder' />
          ) : (
            <NoteLabel key={idx} {...noteLabelSpread({ disabled, note })}>
              <NoteWithSymbols note={flatName || note} />
            </NoteLabel>
          )
        )}
      </AccidentalLabelWrapper>
      <Spacer height='12px' />

      {/* Keyboard */}
      <KeyboardWrapper>
        {/* Natural notes */}
        {NATURAL_NOTE_NAMES({ keyRoot, keyMode }).map(([disabled, note]) => (
          <NaturalNote key={note} disabled={disabled} {...noteSpread(note)} />
        ))}
        {/* Accidental notes */}
        <AccidentalNotesWrapper>
          {PADDED_ACCIDENTAL_NOTE_NAMES({ keyRoot, keyMode }).map(([disabled, note], idx) =>
            note === null ? (
              <AccidentalNote key={idx} className='placeholder' />
            ) : (
              <AccidentalNote key={note} disabled={disabled} {...noteSpread(note)} />
            )
          )}
        </AccidentalNotesWrapper>
      </KeyboardWrapper>
      <Spacer height='12px' />

      {/* Natural label buttons */}
      <Row justify='space-around'>
        {NATURAL_NOTE_NAMES({ keyRoot, keyMode }).map(([disabled, note]) => (
          <NoteLabel key={note} {...noteLabelSpread({ disabled, note })}>
            {note}
          </NoteLabel>
        ))}
      </Row>
    </Wrapper>
  );
}
