import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components/macro';
import Divider from '../../components/Divider';
import Expanded from '../../components/Expanded';
import Row from '../../components/Row';
import ToggleButton from '../../components/ToggleButton';
import { FILTERED_NOTES_FREQS } from '../../lib/config';
import type { KeyMode, Note } from '../../lib/config';
import keyModeAtom from '../../state/keyModeAtom';
import keyRootAtom from '../../state/keyRootAtom';
import showKHzAtom from '../../state/showKhzAtom';
import hoverNoteAtom from '../../state/hoverNoteAtom';
import activeMidiNotesAtom from '../../state/activeNotesAtom';
import NoteWithSymbols from '../../components/NoteWithSymbol';
import { ReactComponent as SearchSvg } from '../../svg/icons/search.svg';
import { useRef } from 'react';
// Styles
const SearchWrapper = styled.div`
  position: relative;
  width: 32px;
  max-width: 192px;
  height: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 999px;
    background-color: ${({ theme }) => theme.primary[400]};
    transform: scaleX(0);
    transition: transform 250ms ${({ theme }) => theme.ease.standard};
  }
  &:focus-within {
    width: 100%;
    &::after {
      transform: scaleX(1);
    }
  }
  button {
    appearance: none;
    outline: none;
    border: none;
    background: none;

    position: absolute;
    top: 4px;
    left: 0;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      path {
        stroke: ${({ theme }) => theme.textFaded};
      }
    }
    &:hover svg path {
      stroke: ${({ theme }) => theme.textColor};
    }
  }
`;
const SearchInput = styled.input`
  background: none;
  border: none;
  outline: none;

  width: 100%;
  padding: 4px 0 4px 32px;
  border-radius: 4px;

  font-weight: 600;

  color: ${({ theme }) => theme.textColor};

  &:focus {
    background-color: ${({ theme }) => theme.hoverBg};
  }
`;
const CopiedFreq = styled.div`
  padding-bottom: 4px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textColor};
  }
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  overflow-y: auto;
  overflow-x: auto;
  /* width: calc(100% - 24px);
  margin-left: 24px; */
  max-width: calc(100% - 48px);
  margin: 0 auto;
`;
const RowWrapper = styled.div<{ isRoot: boolean }>`
  display: contents;
  &:hover > div,
  &.hover > div {
    background-color: ${({ theme }) => theme.hoverBg};
  }
`;
const OctaveTitle = styled.div`
  z-index: 11;

  position: sticky;
  top: 0;
  padding: 8px 0 6px;

  font-size: 14px;
  text-align: center;
  font-weight: bold;

  transition: background-color 500ms ${({ theme }) => theme.ease.standard};
  background-color: ${({ theme }) => theme.sheetColor};
  border-bottom: 2px solid ${({ theme }) => theme.dividerColor};

  &:last-of-type {
    margin-right: 12px;
  }
`;
const RowNote = styled.button`
  z-index: 10;

  appearance: none;
  outline: none;
  border: none;

  position: sticky;
  left: -0.5px;
  width: 32px;
  height: 32px;

  border-right: 2px solid ${({ theme }) => theme.dividerColor};

  transition: background-color 500ms ${({ theme }) => theme.ease.standard};
  background-color: ${({ theme }) => theme.sheetColor};

  white-space: nowrap;
  text-align: center;
  letter-spacing: -1.75px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverBg};
  }

  &.active {
    background-color: ${({ theme }) => theme.primary[300]};
    color: ${({ theme }) => theme.gray[800]};
  }

  svg {
    width: 12px;
    height: 12px;
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;
const RowFreq = styled.div`
  padding: 8px 6px;
  font-size: 12px;
  letter-spacing: 0.015em;
  user-select: all;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  &:first-of-type {
    margin-left: 4px;
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    margin-right: 12px;
    border-radius: 0 4px 4px 0;
  }

  transition: transform 250ms ${({ theme }) => theme.ease.standard},
    background-color 250ms ${({ theme }) => theme.ease.standard};
  &:hover {
    transform: scale(1.25);
    border-radius: 4px;
  }

  /* Highlight from search */
  &.highlight {
    background-color: ${({ theme }) => theme.hoverBg};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.gray[500]};
    border-radius: 4px;
  }
`;

// Component
export default function FreqTable() {
  const [keyRoot, setKeyRoot] = useRecoilState(keyRootAtom);
  const [keyMode, setKeyMode] = useRecoilState(keyModeAtom);
  const [showKHz, setShowKHz] = useRecoilState(showKHzAtom);
  const [hoverNote, setHoverNote] = useRecoilState(hoverNoteAtom);
  const setActiveMidiNotes = useSetRecoilState(activeMidiNotesAtom);

  const filteredNoteFreqs = useMemo(
    () => FILTERED_NOTES_FREQS({ keyRoot, keyMode, showKHz }),
    [keyMode, keyRoot, showKHz]
  );

  // Search for freq
  const searchHzInputRef = useRef<HTMLInputElement>(null);
  const [searchHzInput, setSearchHzInput] = useState('');
  const nearestSearchMatch = useMemo(() => {
    if (searchHzInput.trim()) {
      const flatFreqs = filteredNoteFreqs.flatMap(({ freqs }) => freqs);
      let searchHz = Number(searchHzInput);
      if (isNaN(searchHz)) {
        const isKHz = searchHzInput.toLowerCase().includes('k');
        const withoutUnits = searchHzInput.toLowerCase().replaceAll(/[a-z]/g, '');
        searchHz = Number(withoutUnits);
        if (isNaN(searchHz)) return null;
        if (isKHz) searchHz *= 1000;
      }

      const closestFreq = flatFreqs.reduce(
        (prev, freq) => (Math.abs(freq.hz - searchHz) < Math.abs(prev.hz - searchHz) ? freq : prev),
        flatFreqs[0]
      );
      if (closestFreq) {
        return closestFreq.formatted;
      }
      // const freqs = filteredNoteFreqs.flatMap(({freqs}) => freqs.map(({hz}) => hz}));
      //   const closest = [1, 10, 7, 2, 4].reduce((a, b) => {
      //     return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
      // });
    }

    return null;
  }, [filteredNoteFreqs, searchHzInput]);

  // Copy current/active freq to clipboard
  const [copiedFreq, setCopiedFreq] = useState('');

  // NoteLabel click/unclick handlers
  const rowNoteSpread = useCallback(
    (note: Note) => {
      let className = keyMode !== 'chromatic' && keyRoot === note ? 'active ' : '';
      return {
        className,
        onClick: () => {
          let newMode: KeyMode | null = null;
          setKeyRoot(prev => {
            if (prev === note && keyMode !== 'chromatic') {
              newMode = 'chromatic';
              return prev;
            } else {
              if (keyMode === 'chromatic') newMode = 'major';
              return note;
            }
          });
          if (newMode) setKeyMode(newMode);
        },
      };
    },
    [keyMode, keyRoot, setKeyMode, setKeyRoot]
  );

  return (
    <>
      {/* Freq table header */}
      <Row align='center' padding='0 24px 0'>
        <SearchWrapper>
          <button
            onClick={() => {
              searchHzInputRef.current?.focus();
            }}
          >
            <SearchSvg />
          </button>
          <SearchInput
            ref={searchHzInputRef}
            value={searchHzInput}
            onChange={e => setSearchHzInput(e.target.value)}
            onFocus={() => setCopiedFreq('')}
            // onBlur={() => setSearchHzInput('')}
          />
        </SearchWrapper>
        <Expanded />
        {/* Recently copied value (if any) */}
        {copiedFreq && (
          <CopiedFreq key={copiedFreq} className='fade-out'>
            📋Copied <strong>{copiedFreq}</strong>
          </CopiedFreq>
        )}
        <Expanded />
        {/* Hz vs kHz Toggles */}
        <ToggleButton wide active={!showKHz} onClick={() => setShowKHz(false)}>
          Hz
        </ToggleButton>
        <ToggleButton wide active={showKHz} onClick={() => setShowKHz(true)}>
          kHz
        </ToggleButton>
      </Row>
      <Divider width='calc(100% - 48px)' />

      {/* Freq table */}
      <Table>
        {/* Placeholder ( blank top-left ) */}
        <OctaveTitle>&nbsp;</OctaveTitle>
        {/* Note Octave Titles ( 0 - 11 ) */}
        {Array.from({ length: 11 }, (_val, idx) => (
          <OctaveTitle key={idx}>{idx}</OctaveTitle>
        ))}

        {/* Note/frequency rows */}
        {filteredNoteFreqs.map(({ isRoot, note, flatName, freqs }) => (
          <RowWrapper
            className={hoverNote === note ? 'hover' : ''}
            key={note}
            isRoot={isRoot}
            onMouseEnter={() => setHoverNote(note)}
            onMouseLeave={() => setHoverNote(prev => (prev === note ? null : prev))}
          >
            <RowNote {...rowNoteSpread(note)}>
              <NoteWithSymbols note={flatName || note} />
            </RowNote>
            {freqs.map(freq => (
              <RowFreq
                key={freq.hz}
                className={freq.formatted === nearestSearchMatch ? 'highlight' : ''}
                onClick={() => {
                  navigator.clipboard.writeText(freq.formatted);
                  setCopiedFreq(freq.formatted);
                }}
                onMouseDown={() => setActiveMidiNotes(prev => [...prev, freq.midiNote])}
                // onMouseDown={() => setActiveMidiNotes(prev => [...prev, midiNote])}
                onMouseLeave={() =>
                  setActiveMidiNotes(prev => {
                    const newActiveNotes = [...prev];
                    const idx = newActiveNotes.findIndex(match => match === freq.midiNote);
                    // const idx = newActiveNotes.findIndex(match => match === midiNote);
                    if (idx > -1) {
                      newActiveNotes.splice(idx, 1);
                      return newActiveNotes;
                    }
                    return prev;
                  })
                }
              >
                {freq.formatted}
              </RowFreq>
            ))}
          </RowWrapper>
        ))}
        {/* <Spacer height='16px' /> */}
      </Table>
    </>
  );
}
