import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Divider from '../../components/Divider';
import Row from '../../components/Row';
import Expanded from '../../components/Expanded';

import * as Tone from 'tone';
import type { Synth } from 'tone';

import Keyboard from './Keyboard';
import FreqTable from './FreqTable';
import ToggleButton from '../../components/ToggleButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import keyModeAtom from '../../state/keyModeAtom';
import { useEffect, useMemo, useState } from 'react';
import { activeMidiNoteSelector } from '../../state/activeNotesAtom';
import { FILTERED_NOTES_FREQS } from '../../lib/config';
import keyRootAtom from '../../state/keyRootAtom';
import { useRef } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as MuteSvg } from '../../svg/icons/mute.svg';
import { ReactComponent as UnmuteSvg } from '../../svg/icons/unmute.svg';
import { HSpacer } from '../../components/Spacer';

// Styles
const MuteUnmuteButton = styled.button`
  align-self: flex-start;
  display: flex;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  background: none;

  margin-left: 4px;
  padding: 2px 4px;
  opacity: 0.5;
  transition: opacity 300ms ${({ theme }) => theme.ease.standard};

  svg {
    margin-right: 4px;
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
  span {
    opacity: 0;
    transition: opacity 300ms ${({ theme }) => theme.ease.standard};

    font-size: 14px;
    color: ${({ theme }) => theme.textFaded};
  }
  &:hover {
    opacity: 1;
    span {
      opacity: 1;
    }
  }
`;
const VolumeSlider = styled.input`
  flex: 1;
  appearance: none;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.dividerColor};
  border-radius: 999px;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.textColor};
    cursor: pointer;
  }
`;

// Component
export default function PitchSheet() {
  const [keyMode, setKeyMode] = useRecoilState(keyModeAtom);
  const keyRoot = useRecoilValue(keyRootAtom);

  // Handle audio generation
  const activeMidiNote = useRecoilValue(activeMidiNoteSelector);
  const [synth, setSynth] = useState<Synth | null>(null);

  const hzLookup = useMemo(() => {
    const freqs = FILTERED_NOTES_FREQS({ keyRoot, keyMode, showKHz: false });
    const noteMatch = freqs.find(({ freqs }) =>
      freqs.find(({ midiNote }) => midiNote === activeMidiNote)
    );
    if (noteMatch) {
      const freqMatch = noteMatch.freqs.find(({ midiNote }) => midiNote === activeMidiNote);
      if (freqMatch) {
        return freqMatch.hz;
      }
    }
    return null;
  }, [activeMidiNote, keyMode, keyRoot]);

  // Volume stuff
  const [hoverMuteUnmute, setHoverMuteUnmute] = useState(false);
  const [volume, setVolume] = useState(1);

  // Play note ( and cancel previous )
  const prevHz = useRef<number | null>(null);
  useEffect(() => {
    if (synth && prevHz.current !== hzLookup) {
      synth.triggerRelease();
    }
    if (synth && hzLookup) {
      const now = Tone.now();
      synth.triggerAttack(hzLookup, now, volume);
      prevHz.current = hzLookup;
    } else {
      prevHz.current = null;
    }
  }, [hzLookup, synth, volume]);

  return (
    <Sheet>
      <Row align='flex-end' padding='32px 24px 0'>
        {/* Heading and flats/sharps buttons */}
        <SheetHeading>pitch</SheetHeading>
        {/* Mute/unmute button */}
        <MuteUnmuteButton
          onMouseEnter={() => setHoverMuteUnmute(true)}
          onMouseLeave={() => setHoverMuteUnmute(false)}
          onClick={async () => {
            if (synth) {
              synth.triggerRelease();
              synth.dispose();
              setSynth(null);
            } else {
              await Tone.start();
              setSynth(new Tone.Synth().toDestination());
            }
          }}
        >
          {(!!synth && !hoverMuteUnmute) || (!synth && hoverMuteUnmute) ? (
            <UnmuteSvg />
          ) : (
            <MuteSvg />
          )}
          <span>{!!synth ? 'mute' : 'unmute'}</span>
        </MuteUnmuteButton>

        <Expanded />
        {/* Mode Selector */}
        <ToggleButton
          title='major'
          active={keyMode === 'major'}
          onClick={() => setKeyMode('major')}
        >
          maj
        </ToggleButton>
        <ToggleButton
          title='minor'
          active={keyMode === 'minor'}
          onClick={() => setKeyMode('minor')}
        >
          min
        </ToggleButton>
        <ToggleButton
          title='chromatic'
          active={keyMode === 'chromatic'}
          onClick={() => setKeyMode('chromatic')}
        >
          chrom
        </ToggleButton>
      </Row>
      <Row padding='0 24px 24px'>
        {!!synth ? (
          <>
            <VolumeSlider
              type='range'
              min={0.25}
              max={1.5}
              step={0.05}
              value={volume}
              onChange={e => setVolume(Number(e.target.value))}
            />
            <HSpacer width='8px' />
          </>
        ) : (
          <Divider />
        )}
        <Expanded>
          <Divider />
        </Expanded>
      </Row>

      {/* Keyboard */}
      <Keyboard />

      {/* Freq table */}
      <FreqTable />
    </Sheet>
  );
}
