import React from 'react';
import styled from 'styled-components/macro';
const Row = styled.div<{
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  margin?: React.CSSProperties['margin'];
  padding?: React.CSSProperties['padding'];
}>`
  position: relative;
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin ?? 0};
  padding: ${({ padding }) => padding ?? 0};
`;
export default Row;
