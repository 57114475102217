import { atom, selector } from 'recoil';

const activeMidiNotesAtom = atom<number[]>({
  key: 'activeMidiNotes',
  default: [],
});
export default activeMidiNotesAtom;

export const activeMidiNoteSelector = selector<number | null>({
  key: 'activeMidiNote',
  get: ({ get }) => {
    const activeMidiNotes = get(activeMidiNotesAtom);
    return activeMidiNotes[activeMidiNotes.length - 1] ?? null;
  },
});
