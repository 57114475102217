import { createGlobalStyle, keyframes } from 'styled-components/macro';
import lightTheme from './lightTheme';

export const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;
export const fadeOut = keyframes`
    0% { opacity: 1; }
  100% { opacity: 0; }
`;

const BaseStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  html, body, #app-root {
    position: relative;
    height: 100%;
    width: 100%;
    width: 100vw;
    overflow: hidden;
    background-color: ${lightTheme.bgColor};
    color: ${({ theme }) => theme.textColor};
  }
  a, button{
    &:not(:disabled){
      cursor: pointer;
    }
  }

  ::selection {
    background: rgba(252, 211, 77, .9);
    color: ${({ theme }) => theme.gray[800]};
  }

  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ease 150ms forwards;
  }
  .fade-out {
    animation: ${fadeOut} 400ms ease 3500ms forwards;
  }
`;

export default BaseStyles;
