import styled from 'styled-components/macro';

// Vertical spacer ( default )
const Spacer = styled.div<{ height: string }>`
  flex-shrink: 0;
  height: ${({ height }) => height};
`;
export default Spacer;

// Horizontal spacer
export const HSpacer = styled.div<{ width: string }>`
  flex-shrink: 0;
  width: ${({ width }) => width};
`;
