import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

import type { Note } from '../lib/config';

const keyRootAtom = atom<Note>({
  key: 'keyRoot',
  default: 'c',
  effects_UNSTABLE: localStorageEffect('keyRoot'),
});
export default keyRootAtom;
