import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

import type { KeyMode } from '../lib/config';

const keyModeAtom = atom<KeyMode>({
  key: 'keyMode',
  default: 'chromatic',
  effects_UNSTABLE: localStorageEffect('keyMode'),
});
export default keyModeAtom;
