// ♭ flats
// ♯ sharps

import orderBy from 'lodash/orderBy';

// Types
export type KeyMode = 'major' | 'minor' | 'chromatic';

export type PlaceholderNote = 'b♯' | 'e♯';
export type Note = 'c' | 'c♯' | 'd' | 'd♯' | 'e' | 'f' | 'f♯' | 'g' | 'g♯' | 'a' | 'a♯' | 'b';
export type FlatNote = 'd♭' | 'e♭' | 'g♭' | 'a♭' | 'b♭';

// Config types
type PlaceholderNoteConfig = [note: PlaceholderNote, flatName: null];
type NaturalNoteConfig = [note: Note, flatName: false];
type AccidentalNoteConfig = [note: Note, flatName: FlatNote];

// Main notes config array
const PADDED_ALL_NOTES_CONFIG: (
  | PlaceholderNoteConfig
  | NaturalNoteConfig
  | AccidentalNoteConfig
)[] = [
  ['b♯', null], // PLACEHOLD
  ['c', false],
  ['c♯', 'd♭'],
  ['d', false],
  ['d♯', 'e♭'],
  ['e', false],
  ['e♯', null], // PLACEHOLD
  ['f', false],
  ['f♯', 'g♭'],
  ['g', false],
  ['g♯', 'a♭'],
  ['a', false],
  ['a♯', 'b♭'],
  ['b', false],
  ['b♯', null], // PLACEHOLD
];
// Filtered into relevant config lists
export const ALL_NOTES_CONFIG = PADDED_ALL_NOTES_CONFIG.filter(
  ([, flatName]) => flatName != null
) as (NaturalNoteConfig | AccidentalNoteConfig)[];
const NATURAL_NOTES_CONFIG = ALL_NOTES_CONFIG.filter(
  ([, flatName]) => !flatName
) as NaturalNoteConfig[];
const PADDED_ACCIDENTAL_NOTES_CONFIG = PADDED_ALL_NOTES_CONFIG.filter(
  ([, flatName]) => flatName === null || typeof flatName === 'string'
) as (PlaceholderNoteConfig | AccidentalNoteConfig)[];
const ACCIDENTAL_NOTES_CONFIG = ALL_NOTES_CONFIG.filter(
  ([, flatName]) => typeof flatName === 'string'
) as AccidentalNoteConfig[];

// Key
const SHOW_FLATS: { [key in Note]: [major: boolean, minor: boolean] } = {
  c: [false, true],
  'c♯': [true, false],
  d: [false, true],
  'd♯': [true, true],
  e: [false, false],
  f: [true, true],
  'f♯': [false, false],
  g: [false, true],
  'g♯': [true, false],
  a: [false, false],
  'a♯': [true, true],
  b: [false, false],
};

// Steps for major and minor key modes
const MAJOR_STEPS = [0, 2, 4, 5, 7, 9, 11];
const MINOR_STEPS = [0, 2, 3, 5, 7, 8, 10];

// Filter/format notes ( and enabled/disabled status + assigned names based on key root/mode )
interface GetFormattedNotesProps {
  keyRoot: Note;
  keyMode: KeyMode;
}
// Helper
type FormattedNote =
  | readonly [disabled: true, note: null]
  | readonly [disabled: boolean, note: Note, flatName?: FlatNote];
const GET_FORMATTED_NOTES = (
  config: typeof PADDED_ALL_NOTES_CONFIG,
  { keyRoot, keyMode }: GetFormattedNotesProps
) => {
  const rootMidiIdx = ALL_NOTES_CONFIG.findIndex(([match]) => match === keyRoot);
  const noteNames: FormattedNote[] = config.map(([note, flatName]) => {
    if (flatName === null) return [true, null];
    if (keyMode === 'chromatic') return [false, note as Note];
    // TODO CALC DISABLED
    const noteMidiIdx = ALL_NOTES_CONFIG.findIndex(([match]) => match === note);
    const noteOffset = noteMidiIdx - rootMidiIdx;
    const noteStep = (noteOffset < 0 ? noteOffset + 12 : noteOffset) % 12;
    const steps = keyMode === 'major' ? MAJOR_STEPS : MINOR_STEPS;
    const disabled = !steps.includes(noteStep);

    if (flatName === false) return [disabled, note as Note];
    const showFlat = SHOW_FLATS[keyRoot][keyMode === 'major' ? 0 : 1];
    return showFlat ? [disabled, note as Note, flatName] : [disabled, note as Note];
  });
  return noteNames;
};

// All note names ( w/ padding )
export const PADDED_ALL_NOTE_NAMES = ({ keyRoot, keyMode }: GetFormattedNotesProps) =>
  GET_FORMATTED_NOTES(PADDED_ALL_NOTES_CONFIG, { keyRoot, keyMode });
// All note names ( w/o padding )
export const ALL_NOTE_NAMES = ({ keyRoot, keyMode }: GetFormattedNotesProps) =>
  GET_FORMATTED_NOTES(ALL_NOTES_CONFIG, { keyRoot, keyMode }) as [
    disabled: boolean,
    note: Note,
    flatName?: FlatNote
  ][];

// Natural note names ( w/o padding ) ( no w/ padding ever needed )
export const NATURAL_NOTE_NAMES = ({ keyRoot, keyMode }: GetFormattedNotesProps) =>
  GET_FORMATTED_NOTES(NATURAL_NOTES_CONFIG, { keyRoot, keyMode }) as [
    disabled: boolean,
    note: Note
  ][];

// Accidental note names ( w/ padding )
export const PADDED_ACCIDENTAL_NOTE_NAMES = ({ keyRoot, keyMode }: GetFormattedNotesProps) =>
  GET_FORMATTED_NOTES(PADDED_ACCIDENTAL_NOTES_CONFIG, { keyRoot, keyMode });
// Accidental note names ( w/o padding )
export const ACCIDENTAL_NOTE_NAMES = ({ keyRoot, keyMode }: GetFormattedNotesProps) =>
  GET_FORMATTED_NOTES(ACCIDENTAL_NOTES_CONFIG, { keyRoot, keyMode });

// Frequency types/helpers
type Freq = { hz: number; kHz: number | null; formatted: string; midiNote: number };
interface NoteFreq {
  disabled: boolean;
  note: Note;
  flatName?: FlatNote | null;
  noteStep: number;
  isRoot: boolean;
  freqs: Freq[];
}
// DEFAULTS FOR CALCULATIONS
// https://pages.mtu.edu/~suits/NoteFreqCalcs.html
// the twelth root of 2 = the number which when multiplied by itself 12 times equals 2 = 1.059463094359...
const A = Math.pow(2, 1 / 12);
// the frequency of one fixed note which must be defined.
// A common choice is setting the A above middle C (A4) at f0 = 440 Hz.
const A440 = 440;
// Get formatted, filtered frequency table for given vals
interface NoteFreqsProps extends GetFormattedNotesProps {
  showKHz: boolean;
}
const ALL_NOTES_FREQS = ({ keyRoot, keyMode, showKHz }: NoteFreqsProps) => {
  const rootIdx = ALL_NOTES_CONFIG.findIndex(([match]) => match === keyRoot);
  const allNoteNames = ALL_NOTE_NAMES({ keyRoot, keyMode });

  const freqs: NoteFreq[] = Array.from({ length: 12 }, (_val, noteIdx) => {
    const [disabled, note, flatName] = allNoteNames[noteIdx];

    const isRoot = keyMode !== 'chromatic' && keyRoot === note;
    // Calculate freqs
    const factor = noteIdx + 3; // 3 is the difference between A440 and C ( starting note )
    const freqs = Array.from({ length: 11 }, (_val, octaveIdx) => {
      const pow = factor + 12 * (octaveIdx - 5);
      const hz = A440 * Math.pow(A, pow);
      const kHz = hz >= 1000 ? hz / 1000 : null;
      const formatted = showKHz && kHz ? `${kHz.toFixed(2)}kHz` : hz.toFixed(2);
      return { hz, kHz, formatted, midiNote: 12 + noteIdx + 12 * octaveIdx };
    });
    // Determine show order
    const noteOffset = noteIdx - rootIdx;
    const noteStep = (noteOffset < 0 ? noteOffset + 12 : noteOffset) % 12;

    return { note, midiNote: noteIdx, flatName, disabled, noteStep, isRoot, freqs };
  });
  return keyMode === 'chromatic' ? freqs : orderBy(freqs, 'noteStep');
};

export const FILTERED_NOTES_FREQS = ({ keyRoot, keyMode, showKHz }: NoteFreqsProps) => {
  const allNoteFreqs = ALL_NOTES_FREQS({ keyRoot, keyMode, showKHz });
  return keyMode === 'chromatic'
    ? allNoteFreqs
    : orderBy(
        allNoteFreqs.filter(({ disabled }) => !disabled),
        'noteStep'
      );
};
