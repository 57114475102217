import styled from 'styled-components/macro';

const ToggleButton = styled.button<{ active: boolean; wide?: boolean }>`
  z-index: 11;
  position: relative;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;

  position: relative;
  padding: 8px ${({ wide }) => (wide ? 24 : 12)}px 12px;
  line-height: 1;

  color: ${({ active, theme }) => (active ? theme.textColor : theme.textFaded)};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.015em;

  opacity: ${({ active }) => (active ? 1 : 0.66)};
  transition: opacity 300ms ${({ theme }) => theme.ease.standard};
  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 999px;
    background-color: ${({ theme }) => theme.primary[400]};
    transform: scaleX(${({ active }) => (active ? 1 : 0)});
    transition: transform 250ms ${({ theme }) => theme.ease.standard};
  }
`;
export default ToggleButton;
