import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import isDarkThemeAtom from './state/isDarkThemeAtom';
import darkTheme from './styles/darkTheme';
import Nav from './components/Nav';
import PitchSheet from './sheets/PitchSheet';
import TempoSheet from './sheets/TempoSheet';

// Styles
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  height: 100%;
  max-width: 1024px;
  @media (max-width: 840px) {
    max-width: 540px;
  }
`;
const DarkThemeBg = styled.div<{ active: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${darkTheme.bgColor};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 500ms ${({ theme }) => theme.ease.standard};
`;

const SheetsWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  gap: 16px;
  padding: 0 12px;
  @media (max-width: 840px) {
    display: block;
    overflow: auto;
  }
  @media (max-width: 420px) {
    padding: 0;
  }
`;

// Component
export default function App() {
  const isDarkTheme = useRecoilValue(isDarkThemeAtom);

  return (
    <>
      <DarkThemeBg active={isDarkTheme} />
      <PageWrapper>
        <Nav />

        {/* Sheets */}
        <SheetsWrapper>
          {/* Pitch sheet */}
          <PitchSheet />
          {/* Tempo sheet */}
          <TempoSheet />
        </SheetsWrapper>
      </PageWrapper>
    </>
  );
}
