import styled from 'styled-components/macro';

// Horizontal divider ( default )
const Divider = styled.div<{ width?: React.CSSProperties['width'] }>`
  flex-shrink: 0;
  height: 2px;
  width: ${({ width }) => width ?? '100%'};
  margin: 0 auto;

  background-color: ${({ theme }) => theme.dividerColor};
  border-radius: 999px;
`;
export default Divider;

// Vertical divider
export const VDivider = styled.div<{ height?: React.CSSProperties['height'] }>`
  flex-shrink: 0;
  height: ${({ height }) => height ?? '100%'};
  width: 2px;

  background-color: ${({ theme }) => theme.dividerColor};
  border-radius: 999px;
`;
