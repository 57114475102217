import { FlatNote, Note } from '../lib/config';

import { ReactComponent as FlatSvg } from '../svg/accidentals/flat.svg';
import { ReactComponent as SharpSvg } from '../svg/accidentals/sharp.svg';

export default function NoteWithSymbols({ note }: { note: Note | FlatNote }) {
  const hasFlat = note.includes('♭');
  const hasSharp = note.includes('♯');
  const string = note.replace('♭', '').replace('♯', '');
  return (
    <>
      {string}
      {hasFlat && <FlatSvg />}
      {hasSharp && <SharpSvg />}
    </>
  );
}
